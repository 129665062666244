import './App.css';

import { useEffect, useState } from 'react';

import { createDirectus, rest, readItems, staticToken } from '@directus/sdk';

import Button from '@mui/material/Button'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';

function App() {
  const [exhibition, setExhibition] = useState('');
  const [exhibit, setExhibit] = useState('');
  const [device, setDevice] = useState('');

  const [exhibitSelectDisabled, setExhibitSelectDisabled] = useState(true);
  const [deviceSelectDisabled, setDeviceSelectDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [allExhibitions, setAllExhibitions] = useState([]);
  const [allExhibits, setAllExhibits] = useState([]);
  const [allDevices, setAllDevices] = useState([]);

  const [exhibitsInExhibition, setExhibitsInExhibition] = useState([]);
  const [devicesForExhibit, setDevicesForExhibit] = useState([]);

  const directus = createDirectus('https://setup.click-solutions.com').with(staticToken('EcifHKDKb7OujFPHOndUu7dyyyF0GtSV')).with(rest());

  async function getData() {

    let exhibitions = await directus.request(readItems('exhibitions',
      {
        filter: {
          status: {
            _eq: 'published',
          }
        },
        fields: ["id", "name", { exhibits: ["*"] }],
      }
    ));

    let exhibits = await directus.request(readItems('exhibit',
      {
        fields: ["*", { devices: ["devices_id"] }]
      }
    ));

    let devices = await directus.request(readItems('devices',
      {
        filter: {
          status: {
            _eq: 'published'
          }
        },
        fields: ["id", "name", "hostname", "script"],
        limit: -1
      }));

    setAllExhibitions(exhibitions);
    setAllExhibits(exhibits);
    setAllDevices(devices);
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    //only enable select when items are available
    if (exhibitsInExhibition.length !== 0) {
      setExhibitSelectDisabled(false)
    }
    if (devicesForExhibit.length !== 0) {
      setDeviceSelectDisabled(false)
    }
    //enable button, if device is selected
    if (device !== '') {
      setButtonDisabled(false);
    }
  })

  const handleExhibitionChange = (event) => {
    //disable selects and buttons, only enable when items are available
    setExhibitSelectDisabled(true);
    setDeviceSelectDisabled(true);
    setButtonDisabled(true);

    setExhibition(event.target.value);
    setExhibit('');
    setDevice('');
    setDevicesForExhibit([]);

    // filter exhibits
    let exhibitionHasExhibits = event.target.value.exhibits;

    let exhibitIds = [];
    exhibitionHasExhibits.forEach((exhibit) => {
      exhibitIds.push(exhibit.exhibit_id)
    })

    let helper = [];
    allExhibits.forEach((exhibit) => {
      if (exhibitIds.includes(exhibit.id)) {
        helper.push(exhibit)
      }
    })

    setExhibitsInExhibition(helper);
  };

  const handleExhibitChange = (event) => {
    setExhibit(event.target.value);
    setDevicesForExhibit([]);
    setDevice('');
    setDeviceSelectDisabled(true);
    setButtonDisabled(true);

    // filter devices
    let exhibitUsesDevices = event.target.value.devices;

    let deviceIds = [];
    exhibitUsesDevices.forEach((device) => {
      deviceIds.push(device.devices_id);
    })

    let helper = [];
    allDevices.forEach((device) => {
      if (deviceIds.includes(device.id)) {
        helper.push(device);
      }
    })

    setDevicesForExhibit(helper);
  }

  const handleDeviceChange = (event) => {
    setDevice(event.target.value);
    if (event.target.value.script === null) {
      alert("No Script available for this device.\nPlease Select another one.")
    }
  }

  return (
    <div className="App">
      <h1>Setup Konfigurator</h1>
      <p>Wähle Messe, Exponat und Device aus und lade die Setup-Datei für dieses Gerät herunter.</p>
      <div className="select-container">
        <Box className="select-box">
          <FormControl fullWidth>
            <InputLabel className="select-label" id="label-for-exhibition">
              Messe
            </InputLabel>
            <Select
              labelId="label-for-exhibition"
              label="Messe"
              value={exhibition}
              onChange={handleExhibitionChange}>
              {
                allExhibitions.map((exhibition) => {
                  return (
                    <MenuItem
                      value={exhibition}
                      key={exhibition.id}>
                      {exhibition["name"]}
                    </MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Box>
        <Box className="select-box">
          <FormControl fullWidth disabled={exhibitSelectDisabled}>
            <InputLabel className="select-label" id="label-for-exhibit">
              Exponat
            </InputLabel>
            <Select
              labelId="label-for-exhibit"
              label="Exponat"
              value={exhibit}
              onChange={handleExhibitChange}>
              {
                (exhibitsInExhibition.length !== 0) ?
                  exhibitsInExhibition.map((exhibit) => {
                    return (
                      <MenuItem
                        value={exhibit}
                        key={exhibit.id}>
                        {exhibit["name"]}
                      </MenuItem>)
                  }) : null
              }
            </Select>
          </FormControl>
        </Box>
        <Box className="select-box">
          <FormControl fullWidth disabled={deviceSelectDisabled}>
            <InputLabel className="select-label" id="label-for-device">
              Device
            </InputLabel>
            <Select
              labelId="label-for-device"
              label="Device"
              value={device}
              onChange={handleDeviceChange}>
              {
                devicesForExhibit.map((device) => {
                  return (
                    <MenuItem
                      value={device}
                      key={device.id}>
                      {device["name"]}
                    </MenuItem>)
                })
              }
            </Select>
          </FormControl>
        </Box>
      </div>
      <a href={"https://setup.click-solutions.com/scripts/" + device.id}>
        <Button variant='contained' disabled={buttonDisabled}>
          Download Setup File
        </Button>
      </a>
    </div>
  );
}

export default App;